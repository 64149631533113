import groq from "groq"

const id = groq`
    "id": coalesce(_id, _key)
`

// noIndex in case the page is in a "Text/Experiment" folder (Business and Consumer)
export const seo = groq`
    seo {
        title,
        description,
        type,
        "imageUrl": image.asset->url,
        "noIndex": seo.noIndex || ^.folder._ref == "816a9fc9-5486-4681-ab5c-c85a46573096" || ^.folder._ref ==  "a7a87df9-2acf-4042-98b4-2854c7e3a55b"
    }
`
const blogPostLinkUrl = groq`
    segment,
    lang,
    lang in ["dk", "en"] => {
        "slug": "blog/" + category->slug.current + "/" + slug.current
    },
    lang in ["se", "no"] => {
        "slug": "blogg/" + category->slug.current + "/" + slug.current
    },
    "type": _type,
`
export const blogPostLink = groq`
"link": {
    "linkType": "internal",
    "url": { ${blogPostLinkUrl} }
}`
const linkUrl = groq`
select(
    coalesce(type, linkType) == "internal" => // The name "type" used by rich text, "linkType" used by CTA
        select(
            internalLink->_type == "blogPost" => internalLink->{ ${blogPostLinkUrl} },
            internalLink->_type != "blogPost" => internalLink->{ "lang": lang, "segment": segment, "slug": slug.current, "type": _type },
        ),
    coalesce(type, linkType) == "external" => externalLink,
)`

export const link = groq`
    ${id},
    "type": _type,
    "linkType": type,
    "url": ${linkUrl},
    title,
    gtmEvent,
    event,
    withArrow,
    markAsNew
`

export function video(target = "video", source = target) {
    return groq`
        "${target}": {
            "type": "video",
            "src": ${source}.url,
            "height": ${source}.oEmbedData.height,
            "width": ${source}.oEmbedData.width,
            "duration": ${source}.oEmbedData.duration,

            "autoplay": ${source}.oEmbedConfigData.autoplay,
            "controls": ${source}.oEmbedConfigData.controls,
            "loop": ${source}.oEmbedConfigData.loop,
            "muted": ${source}.oEmbedConfigData.muted,
            "thumbnail": {
                "url": ${source}.oEmbedData.thumbnail_url,
                "height": ${source}.oEmbedData.thumbnail_height,
                "width": ${source}.oEmbedData.thumbnail_width,
            },
        }
    `
}

export function richtext(target = "video", source = target) {
    return groq`
        "${target}": ${source}[] {
            ...,
            markDefs[] {
                ...,
                _type == "link" => {
                    ${link}
                }
            }
        }
    `
}

const productMinimal = groq`
    ${id},
    ${image("backgroundImage")},
    title,
    "page": page -> {
        "title": title,
        "linkType": "internal",
        "url": { "lang": lang, "segment": segment, "slug": slug.current, "type": _type }
    },
    shortDescription,
    badge,
    badgeTheme
`

const subscriptionMinimal = groq`
    ${id},
    title,
    price,
    priceYearly,
    ${richtext("description")},
    "page": page -> {
        "title": title,
        "linkType": "internal",
        "url": { "lang": lang, "segment": segment, "slug": slug.current, "type": _type }
    },
    ${cta("getAppLink")},
    ${image("cardImage")},
    cardImageAlternatives[] {
        ${image("image", "image")},
    }
`
const blogCategory = groq`
"category": {
    "title": category->title[$locale],
    "englishTitle": category->title["en"],
    "slug": category->slug.current,
    "_id": category._ref,
}`

const blogPostTeaser = groq`
    ${id},
    title,
    ${blogCategory},
    lang,
    segment,
    "slug": slug.current,
    publishedAt,
    ${blogPostLink},
    ${image("mainImage")},
    intro
`
const moduleResolver = (moduleType: "inline" | "reusable") => groq`
    _type == "${moduleType}CurrencyConverterModule" => {
        ${id},
        title,
        introText,
        removeMarginTop,
        removeMarginBottom
    },
    _type == "${moduleType}CurrencyTableModule" => {
        ${id},
        title,
        onlyPopular,
    },
    _type == "${moduleType}CustomComponentModule" => {
        ...
    },
    _type == "${moduleType}ProductListModule" => {
        ${id},
        title,
        ${cta()},
        list[]-> {
            ${productMinimal}
        }
    },
    _type == "${moduleType}SubscriptionsListModule" => {
        ...,
        ${id},
        ${richtext("title")},
        ${cta()},
        subscriptions[] -> {
            ${subscriptionMinimal}
        },
    },
    _type == "${moduleType}HeadlineTextAndCtaModule" => {
        ...,
        ${richtext("body")},
        ${richtext("title.text")},
        ${cta()},
    },
    _type == "${moduleType}FeaturesGroupModule" => {
        ${id},
        textPosition,
        title,
        features[] {
            ${id},
            title,
            ${richtext("text")},
            ${image("frontImage")},
            frontImagePosition,
            ${image("midImage")},
            midImagePosition,
            shapeColor,
            shapePosition,

        }
    },
    _type == "${moduleType}SubscriptionTableModule" => {
        ${id},
        title,
        bodyText,
        ${cta()},
        benefitGroups[] -> {
            ${id},
            title,
            benefitTypes[]-> {
                ${id},
                title,
                ${richtext("tooltipText")},
                badgeText,
            }
        },
        subscriptions[] -> {
            ${id},
            title,
            price,
            priceYearly,
            ${richtext("description")},
            "page": page -> {
                "title": title,
                "linkType": "internal",
            },
            sellingpoints,
            ${image("cardImage")},
            benefits[] {
                ${id},
                "labelId": label->_id,
                style,
                value,
            },
            ${cta("getAppLink")},
        }
    },
    _type == "${moduleType}ImageModule" => {
        ${id},
        maxWidth,
        ${media()},
        backgroundColor,
        ${media("mobileMedia")},
        removeMargin,
    },
    _type == "${moduleType}UspModule" => {
        ${id},
        backgroundColor,
        circleColor,
        contrastColor,
        textColor,
        removeMargin,
        items[] {
            ${id},
            icon,
            body,
        }
    },
    _type == "${moduleType}LeadParagraphModule" => {
        ${id},
        ${cta()},
        ${richtext("body")},
        removeMarginTop,
        removeMarginBottom,
        talkieReversedColor,
        talkieColor,
        waffleColor,
        stickColor
    },
    _type == "${moduleType}LoanModule" => {
        ${id},
        loanModuleTitle,
        howMuchWillYouBorrowSubtitle,
        howMuchWillYouPayOff,
        periodInformation,
        currency,
        originationFeeRebate,
        originationFeeRebateLabel,
        originationFeeRebateButtonLabel,
        originationFeeRebateModalTitle,
        originationFeeRebateModalContent,
        minLoanValue,
        maxLoanValue,
        minLoanPeriod,
        maxLoanPeriod,
        yearsText,
        exampleInterestRateTitle,
        rateOfAnnualInterestRebate,
        rateOfAnnualInterestRebateLabel,
        rateOfAnnualInterestRebateButtonLabel,
        rateOfAnnualInterestRebateModalTitle,
        rateOfAnnualInterestRebateModalContent,
        effectiveExampleInterestrate,
        longestInterestRateTitle,
        minLongestInterestRate,
        rewardButton,
        ${richtext("rewardsModal")},
        exampleOfInterestTitle,
        basicReuqirements,
        ${richtext("basicReuqirementsModal")},
        interestRateDetermined,
        ${richtext("interestRateDeterminedModal")},
        representativeExampleContent,
        interestRateDeterminedModalTitle,
        basicReuqirementsModalTitle,
        rewardsModalTitle,
        applyForLoan,
    },
    _type == "${moduleType}RichTextModule" => {
        ${id},
        ${richtext("body")},
        removeMarginTop,
        removeMarginBottom
    },
    _type == "${moduleType}RibbonModule" => {
        ${id},
        "ribbonType": coalesce(ribbonType, "scrolling-news-ticker"),
        backgroundColor,
        ${ctaAnonymous()},
        textColor,
        list[] {
            ${id},
            title,
        },
        ${richtext("staticRibbonText")},
        ${cta("staticRibbonCta")},
        removeMarginTop,
        removeMarginBottom
    },
    _type == "${moduleType}TestimonialsModule" => {
        ${id},
        title,
        ${media("media")},
        rows,
        backgroundColor,
        list[] {
            ${id},
            byline1,
            byline2,
            body,
            ${image()},
            rating
        },
        scrollLogosHorizontally,
        providers[]  {
            "id": _key,
            provider,
            rating,
            ${image()},
        },
        removeMarginTop,
        removeMarginBottom,
    },
    _type == "${moduleType}KeyNumbersModule" => {
        ${id},
        backgroundColor,
        valueColor,
        items[] {
            ${id},
            value,
            body,
            suffix
        }
    },
    _type == "${moduleType}CampaignModule" => {
        title,
        items[]{
            title,
            ${media("media")},
            "overlayMedia": {
                ${media("media", "overlayMedia.media")},
                "position": overlayMedia.position
            }
        },
        ${cta("cta")},
    },
    _type == "${moduleType}CollageDefaultModule" => {
        ${media("media")},
    },
    _type == "${moduleType}CollageModule" => {
        ${id},
        ...,
        "backgroundMedia": {
            ${media("media", "backgroundMedia.media")},
            ${media("mobileMedia", "backgroundMedia.mobileMedia")},
            "position": "background-fill", // Only support background-fill right now
            // "position": coalesce(backgroundMedia.position, "background-fill"),
        },
        "box1": {
            ...box1,
            "embeddedContent": box1.embeddedContent[] {
                ...,
                ...select(_type == "inlineStepsModule" => {
                    "value": "prop",
                    steps[] {
                        ${id},
                        ${richtext("text")},
                        circleText,
                        ${cta()},
                        title
                    }
                })
            },
            "title": {
                ...box1.title,
                ${richtext("text", "box1.title.text")},
                "size": coalesce(box1.title.size, "heading-200")
            },
            "bodyText": {
                ...box1.bodyText,
                ${richtext("text", "box1.bodyText.text")},
            },
            ${cta("cta", "box1.cta")},
            ${cta("secondaryCta", "box1.secondaryCta")},
            "media": {
                "position": coalesce(box1.media.position, "center"),
                ${media("media", "box1.media.media")},
                ${media("mobileMedia", "box1.media.mobileMedia")},
                "animation": box1.media.animation,
            },
            "overlayMedia": {
                "position": coalesce(box1.overlayMedia.position, "center"),
                ${media("media", "box1.overlayMedia.media")},
                "animation": box1.overlayMedia.animation,
            },
            ${richtext("disclaimer", "box1.disclaimer")},
        },
        "box2": {
            ...box2,
            "title": {
                ...box2.title,
                ${richtext("text", "box2.title.text")},
                "size": coalesce(box2.title.size, "heading-200")
            },
            "bodyText": {
                ...box2.bodyText,
                ${richtext("text", "box2.bodyText.text")},
            },
            ${cta("cta", "box2.cta")},
            ${cta("secondaryCta", "box2.secondaryCta")},
            "media": {
                "position": coalesce(box2.media.position, "center"),
                ${media("media", "box2.media.media")},
                ${media("mobileMedia", "box2.media.mobileMedia")},
                "animation": box2.media.animation,
            },
            "overlayMedia": {
                "position": coalesce(box2.overlayMedia.position, "center"),
                ${media("media", "box2.overlayMedia.media")},
                "animation": box2.overlayMedia.animation,
            },
            ${richtext("disclaimer", "box2.disclaimer")},
        },
    },
    _type == "${moduleType}TextGridModule" => {
        ${id},
        ${richtext("title")},
        ${richtext("bodyText")},
        columns,
        ${cta()},
        ${richtext("disclaimer")},
        items[] {
            ${id},
            title,
            ${richtext("body")},
            ${cta()},
            ${media()},
            mediaStyle,
            mediaOrientation
        },
        backgroundColor,
        removeMarginTop,
        removeMarginBottom
    },
    _type == "${moduleType}BlogPostGridModule" => {
        ${id},
        title,
        columns,
        rows,
        categories,
        blogPostSource,
        blogPostSource == "categories" => {
            "blogPosts": *[_type == 'blogPost' && category._ref in ^.categories[]._ref && lang == $locale && segment == $segment] | order(publishedAt desc) {
                ${blogPostTeaser}
            } [0...12]
        },
        blogPostSource == "manuallySelected" => {
            "blogPosts": blogPosts[]->{
                ${blogPostTeaser}
            }
        },
    },
    _type == "${moduleType}QaListModule" => {
        ${id},
        title,
        bodyText,
        ${cta()},
        ${cta("secondaryCta")},
        list[]  {
            ${id},
            ${richtext("text")},
            title
        }
    },
    _type == "${moduleType}StepsModule" => {
        ${id},
        title,
        circleColor,
        direction,
        steps[] {
            ${id},
            ${richtext("text")},
            circleText,
            ${cta()},
            title
        }
    },
    _type == "${moduleType}ProductOverviewModule" => {
        ${id},
        "categories": categories[] -> {
            ${id},
            title,
            products[]-> {
                ${productMinimal}
            }
        }
    },
    _type == "${moduleType}VideoModule" => {
        ${id},
        title,
        ${richtext("body")},
        img_url,
        ${video()}
    },
    _type == "${moduleType}FeatureWalkthroughModule" => {
        "id": _id,
        title,
        list[]  {
            "id": _key,
            ${richtext("body")},
            ${media()},
            duration
        },
    },
    _type == "${moduleType}InvestmentModule" => {
        "id": _id,
        rate,
        period,
        maxYears,
        currency,
        tooltipTitle,
        zeroInterestRateLabel,
        estimatedRefundLabel,
        investmentInputStartLabel,
        investmentInputMonthlyLabel,
        investmentInputStartPlaceholder,
        investmentInputMonthlyPlaceholder,
        startInvestmentTabs[] {
            ${id},
            moneyAmount,
            tabCurrency
        },
        monthlyInvestmentTabs[] {
            ${id},
            moneyAmount,
            tabCurrency
        },
        radioLabels[] {
            ${id},
            radioLabel
        },
        initialStepHeading,
        initialStepDescription,
        initialStepButtonLabel,
        initialStepExamplePrincipal,
        initialStepExampleMontlyAmount,
        initialStepInfoboxHeadline,
        initialStepInfoboxSubHeadline,
        initialStepInfoboxPrincipalLabel,
        initialStepInfoboxRateLabel,
        initialStepInfoboxMonthlyPaymentsLabel,
        initialStepInfoboxMaxYearsUnitLabel,
        initialStepInfoboxMaxYearsLabel,
        firstMiddleStepNumber,
        firstMiddleStepHeading,
        firstMiddleStepDescription,
        secondMiddleStepNumber,
        secondMiddleStepHeading,
        secondMiddleStepDescription,
        thirdMiddleStepNumber,
        thirdMiddleStepHeading,
        thirdMiddleStepDescription,
        middleStepPreviousButtonLabel,
        middleStepNextButtonLabel,
        thirdMiddleStepSeeResultButtonLabel,
        finalStepTitle,
        finalStepCalculationDescription,
        finalStepButtonLabel,
        finalStepModalButtonText,
        finalStepStartMoneyAmountSummary,
        finalStepMonthlyMoneyAmountSummary,
        finalStepRadioInputSummary,
        howisThisCalculatedModalContent,
        howisThisCalculatedModalTitle,
        riskInfo
    },
    _type == "${moduleType}TypeFormModule" => {
        ${id},
        formId
    },
    _type == "${moduleType}RateTableModule" => {
        title,
        dataset,
        currency,
        minLabel,
        maxLabel,
        avgLabel,
        expectedCreditLosses,
        removeMarginTop,
        removeMarginBottom
    },
    _type == "inlineTableModule" => {...}
`

export const modules = groq`
    modules[] {
        ...,
        "type": lower(array::join(string::split(string::split(string::split(_type, "inline")[-1], "reusable")[-1], "")[0...1], "")) + array::join(string::split(string::split(string::split(_type, "inline")[-1], "reusable")[-1], "")[1...100], ""),
        ${moduleResolver("inline")},
        _type == "reference" => *[_id == ^._ref][0] {
            "type": lower(array::join(string::split(string::split(string::split(_type, "inline")[-1], "reusable")[-1], "")[0...1], "")) + array::join(string::split(string::split(string::split(_type, "inline")[-1], "reusable")[-1], "")[1...100], ""),
            ${moduleResolver("reusable")}
        }
    }
`

export function image(target = "image", source = target) {
    return groq`
        "${target}": {
            "type": "image",
            "src": ${source}.asset->url,
            "placeholder": ${source}.asset->metadata.lqip,
            "alt": coalesce(${source}.alt, ${source}.asset->alt),
            "height": ${source}.asset->metadata.dimensions.height,
            "width": ${source}.asset->metadata.dimensions.width,
            "crop": ${source}.crop,
            "hotspot": ${source}.hotspot,
            "originalFilename": string::split(${source}.asset->originalFilename, ".")[0],
        }
    `
}

export function media(target = "media", source = target) {
    return groq`
        "${target}": ${source} {
            "type": type,
            ...select(
                type == "image" => {
                    ${image("image")}
                }["image"],
                type == "video" => {
                    ${video("video")}
                }["video"],
                type == "vimeoFiles" => {
                    ...
                }["vimeoFiles"],
            ),
        }
    `
}

export function cta(target = "cta", source = target) {
    return groq`
        "${target}": coalesce(${source} {
            "type": "cta",
            ${id},
            style,
            "link": {
                "type": "link",
                linkType,
                title,
                gtmEvent,
                "url": ${linkUrl},
                event
            }
        }, { "type": "cta", "style": "" })
    `
}

export function ctaAnonymous(target = "cta", source = target) {
    return groq`
        "${target}": coalesce(${source} {
            "type": "ctaAnonymous",
            "link": {
                "type": "link",
                linkType,
                "url": ${linkUrl},
                event
            }
        }, { "type": "cta" })
    `
}


function getNestedLinkGroup(levels = 5) {
    if (levels === 0) {
        return groq`
            _type == "link" => {
                ${link},
            }
        `
    }

    return groq`
        _type == "link" => {
            ${link},
        },
        _type == "linkGroup" => {
            ${id},
            "type": _type,
            label,
            groupWithPrevious,
            children[] {
                ${getNestedLinkGroup(levels - 1)}
            }
        }
    `
}

export const globalSettings = groq`
    *[_type == "globalSettings" && lang == $locale && segment == $segment][0] {
        ${id},
        description,
        title,
        "navigation": *[_type == "navigationSettings" && lang == $locale && segment == $segment][0] {
            header[] {
                ${getNestedLinkGroup(3)}
            },
            footer[] {
                ${getNestedLinkGroup(3)}
            },
        },
        segment,
        lang,
        "settings": *[_type == "globalSettings"] {
            segment == "private" => {
                "title": translations["header"]["private"],
                segment,
                lang,
                "link": {
                    "title": translations["header"]["private"],
                    "linkType": "internal",
                    "url": { "lang": lang, "segment": segment, "slug": "", "type": _type }
                }
            },
            segment == "business" => {
                "title": translations["header"]["business"],
                segment,
                lang,
                "link": {
                    "title": translations["header"]["private"],
                    "linkType": "internal",
                    "url": { "lang": lang, "segment": segment, "slug": "", "type": _type }
                }
            },
        },
        translations,
        headerCTALabel,
        soMeNav,
        ${richtext("legalText")},
        linkGooglePlay,
        linkAppStore,
        faq {
            "link": link -> {
                "title": title,
                "linkType": "internal",
                "url": { "lang": lang, "segment": segment, "slug": slug.current, "type": _type }
            },
            backLink,
        },
        "notfoundPage": notfoundPage -> {
            "title": title,
            "linkType": "internal",
            "url": { "lang": lang, "segment": segment, "slug": slug.current, "type": _type }
        },
        "errorPage": errorPage -> {
            "title": title,
            "linkType": "internal",
            "url": { "lang": lang, "segment": segment, "slug": slug.current, "type": _type }
        },
        seo {
            title,
            description,
            type,
            "imageUrl": seo.image.asset->url,
            noIndex,
        },
        "downloadDetails": downloadOverlay->{ ... },
        newsRibbon {
            ${id},
            ribbonType,
            backgroundColor,
            ${ctaAnonymous()},
            textColor,
            list[] {
                ${id},
                title,
            },
            ${richtext("staticRibbonText")},
            ${cta("staticRibbonCta")},
            removeMarginTop,
            removeMarginBottom
        },
        showOnlyNewsRibbonOnFrontpage,
        loginDetails{
            accountTitle,
            appAccountTitle,
            appAccountCTA,
            loginTitle,
            loginCTA,
            loginButton,
            loginSubtitle,
            mobileID,
            bankIDOnFile,
            getBankID,
            ${media("loginMedia")}
        },
    }
`

const pageReferences = (id: string) => groq`
    *[_type == "pageReference" && references(${id})][0].pages[] {
        lang,
        "page": page -> {
            "title": title,
            "linkType": "internal",
            "url": { "lang": lang, "segment": segment, "slug": slug.current, "type": _type }
        },
    }
`

export const frontpage = groq`
    *[_type == "frontpage" && segment == $segment && lang == $locale] {
        ${id},
        "refs": ${pageReferences("^._id")},
        "headerStyle": coalesce(headerStyle, "dark"),
        "pageThemeType": coalesce(pageThemeType, "light"),
        "pageThemeColor": coalesce(pageThemeColor, "transparent"),
        "downloadOverlay": downloadOverlay->{ ... },
        ${cta("headerCTAOverwrite")},
        title,
        body,
        ${modules},
        ${seo},
        "updatedAt": _updatedAt
    }
`

export const page = groq`
    *[_type == "page" && slug.current == $slug && lang == $locale && segment == $segment] {
        ${id},
        "refs": ${pageReferences("^._id")},
        "headerStyle": coalesce(headerStyle, "dark"),
        "pageThemeType": coalesce(pageThemeType, "light"),
        "pageThemeColor": coalesce(pageThemeColor, "transparent"),
        "downloadOverlay": downloadOverlay->{ ... },
        ${cta("headerCTAOverwrite")},
        title,
        slug,
        ${image("backgroundImage")},
        ${modules},
        ${seo},
        "folderref": folder._ref,
        "updatedAt": _updatedAt
    }
`

export const inAppPage = groq`
    *[_type == "inAppPage" && slug.current == $slug && lang == $locale && segment == $segment] {
        ${id},
        ${cta("floatingCta")},
        pageThemeType,
        title,
        slug,
        ${modules},
        "updatedAt": _updatedAt
    }
`

export const blogOverview = groq`
    {
        "blogPosts": *[!(_id in path("drafts.**")) && _type == 'blogPost' && lang == $locale && segment == $segment] | order(publishedAt desc) {
            ${blogPostTeaser}
        }[0...16],
        "categories": *[_type == "category" && count(*[_type == "blogPost" && lang == $locale && segment == $segment && references(^._id) && !(_id in path("drafts.**"))]) > 0 && !(_id in path("drafts.**"))] {
            "title": title[$locale],
            "slug": slug.current,
            _id,
        }
    }
`

export function blogOverviewPaged(lastPublishedAt, category) {
    return groq`
        {
            "blogPosts": *[
                !(_id in path("drafts.**"))
                && _type == 'blogPost'
                && lang == $locale
                && segment == $segment
                ${ lastPublishedAt ? groq`&& publishedAt < $lastPublishedAt` : ""}
                ${ category ? groq`&& category._ref == $category` : ""}
                ] | order(publishedAt desc) {
                ${blogPostTeaser}
            }[0...16]
        }
    `
}
export const blogOverviewGroupedByCategory = groq`
    {
        "categories": *[_type == 'category'] {
            "title": title[$locale],
            "slug": slug.current,
            "blogPosts": *[_type == 'blogPost' && category._ref == ^._id && lang == $locale && segment == $segment] | order(publishedAt desc) {
                ${blogPostTeaser}
            }[0...3]
        }
    }
`
// Used to check whether the slug exists as a blog or page
export const blogPostExistsInDraft = groq`
    *[lang == $locale && segment == $segment && _type == "blogPost" && slug.current == $slug] {
        _type
    }
`
export const blogPostExists = groq`
    *[!(_id in path("drafts.**")) && lang == $locale && segment == $segment && _type == "blogPost" && slug.current == $slug] {
        _type
    }
`
export const blogPost = groq`
    *[_type == "blogPost" && slug.current == $slug && lang == $locale && segment == $segment] {
        ${id},
        "refs": ${pageReferences("^._id")},
        "headerStyle": coalesce(headerStyle, "dark"),
        "pageThemeType": coalesce(pageThemeType, "light"),
        "pageThemeColor": coalesce(pageThemeColor, "transparent"),
        lang,
        ${cta("headerCTAOverwrite")},
        title,
        author,
        ${blogCategory},
        intro,
        ${blogPostLink},
        publishedAt,
        updatedAt,
        segment,
        "slug": slug.current,
        ${image("mainImage")},
        ${modules},
        ${seo},
        "relatedBlogPosts": *[_type == 'blogPost' && lang == $locale && segment == $segment && category._ref == ^.category._ref && slug.current != $slug] | order(publishedAt desc) {
            ${blogPostTeaser}
        }[0...4]
    }
`

export const scheduled = groq`
    *[_type == "schedule.metadata" && !(_id in path("drafts.**")) && datetime <= now()]
`
