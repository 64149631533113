import React, { useEffect, useState } from "react"
import Head from "next/head"
import { Header } from "../header/Header"
import { Footer } from "../footer/Footer"
import { GlobalSettings, HeaderStyle, PageReference, PageThemeType, SanityImage } from "src/sanity/types"
import buildLink from "../../sanity/buildLink"
import { SettingsContext } from "./settingsContext"
import { Provider as BusProvider } from "react-bus"
import { OverwriteProvider } from "./overwriteContext"
import s from "./Page.module.scss"
import cn from "classnames"
import { urlFor } from "src/sanity/sanity"
import { slugify } from "src/templates/blog/blogHelper"
import { DocTypeMap } from "src/templates"
import { ExperimentsProvider } from "./experimentsContext"
import { initialize as initializeTextBalance } from "../../lib/text-balancer"
import RibbonModule from "src/modules/ribbon-module/RibbonModule"

interface PageProps {
    children: React.ReactNode
    pageTitle?: string
    title?: string
    description?: string
    globalSettings: GlobalSettings
    type?: string
    imageUrl?: string
    url?: string
    pageReferences?: PageReference[]
    headerStyle: HeaderStyle
    pageThemeType?: PageThemeType
    pageThemeColor?: string
    backgroundImage?: SanityImage
    docType?: keyof DocTypeMap
    noIndex?: boolean
    documentId?: string
}

export default function Page({
    url,
    type = "website",
    globalSettings,
    pageReferences,
    pageTitle,
    title,
    imageUrl,
    headerStyle,
    pageThemeType,
    pageThemeColor,
    backgroundImage,
    description,
    noIndex,
    docType,
    children,
    documentId,
}: PageProps) {
    const [secretWordToGoToSanity, setSecretWordToGoToSanity] = useState("")
    useEffect(() => {
        const checkIfSecretWordIsEntered = (e: KeyboardEvent) => {
            if (e.target instanceof Element && e?.target?.nodeName === "INPUT")
                return
            setSecretWordToGoToSanity((lastWord) => lastWord + e.key)
        }
        document.addEventListener("keyup", checkIfSecretWordIsEntered)

        return () => document.removeEventListener("keyup", checkIfSecretWordIsEntered)
    }, [])
    useEffect(() => {
        if ((secretWordToGoToSanity.endsWith("sanity") || secretWordToGoToSanity.endsWith("andreaserkonge")) && documentId) {
            const domain = (window.location.hostname.includes("local"))
                ? "http://localhost:3333"
                : "https://lunar-website-studio.vercel.app"

            window.open(`${domain}/intent/edit/id=${documentId}`, "_blank")
        }
    }, [documentId, secretWordToGoToSanity])

    const alternatives = (pageReferences || []).map(({ lang, page }) => {
        return {
            hreflang: lang,
            href: buildLink(page),
        }
    })

    if (url?.includes("banking-services")) {
        url = url.replace("/business/", "/")
    }

    useEffect(() => {
        try {
            initializeTextBalance()
        } catch (error) {
            //
        }
    }, [children])

    useEffect(() => {
        document.body.classList.add("segment-" + globalSettings?.segment)

        return () => document.body.classList.remove("segment-" + globalSettings?.segment)
    }, [globalSettings])

    const hasNewsRibbon = globalSettings?.newsRibbon?.ribbonType && (!globalSettings.showOnlyNewsRibbonOnFrontpage || (globalSettings.showOnlyNewsRibbonOnFrontpage && docType === "frontpage"))

    return (
        <BusProvider>
            <SettingsContext.Provider value={globalSettings}>
                <OverwriteProvider>
                    <ExperimentsProvider url={url} segment={globalSettings.segment} lang={globalSettings.lang}>
                        <div style={{
                            "--page-theme-background-color": pageThemeColor,
                            "backgroundImage": backgroundImage?.src ? `url("${backgroundImage.src}")` : undefined,
                        } as React.CSSProperties} className={
                            cn(
                                {
                                    [s["dark-theme"]]: pageThemeType === "dark",
                                    [s["shareit-theme"]]: pageThemeType === "shareit",
                                    "shareit-theme": pageThemeType === "shareit",
                                    [s["has-background"]]: backgroundImage?.src,
                                },
                                "doc-" + docType,
                                "segment-" + globalSettings.segment,
                                url ? "path-" + slugify((new URL(url)).pathname) : undefined,
                            )
                        }>
                            <Head>
                                <meta name="title" content={title} />
                                <meta name="description" content={description} />
                                <meta name="theme-color" content={globalSettings.segment === "private" ? "#ffffff" : "#000000"} />

                                <title>{`${pageTitle} — Lunar`}</title>

                                {/* If "url" is falsy then the page shouldn't have SEO tags. */}
                                {url && (
                                    <>
                                        <meta property="og:description" content={description} />
                                        <meta property="og:title" content={pageTitle} />
                                        <meta property="og:type" content={type} />
                                        <meta property="og:url" content={url} />
                                        {imageUrl && (
                                            <meta property="og:image" content={imageUrl.includes("sanity.io") ? urlFor(imageUrl).width(1080).quality(60).url() : imageUrl} />
                                        )}

                                        <link rel="canonical" href={url} />
                                        {alternatives.map(({ href, hreflang }, i) => <link key={i} rel="alternative" href={href} hrefLang={hreflang} />)}
                                    </>
                                )}
                                {(noIndex || !url) && <meta name="robots" content="noindex" />}
                            </Head>

                            {hasNewsRibbon && (
                                <>
                                    <style jsx global>{`body {--news-ribbon-height: 60px;}`}</style>
                                    <RibbonModule {...globalSettings.newsRibbon} className={s["news-ribbon"]} />
                                </>
                            )}

                            {docType !== "inAppPage" && (
                                <Header style={headerStyle} globalSettings={globalSettings} pageThemeType={pageThemeType} pageThemeColor={pageThemeColor} documentId={documentId} />
                            )}

                            <main className={cn(s["main"], headerStyle?.includes("transparent") ? s["main--no-top-margin"] : null)}>
                                {children}
                            </main>
                            {docType !== "inAppPage" && (
                                <Footer pageReferences={pageReferences} url={url} />
                            )}
                        </div>
                    </ExperimentsProvider>
                </OverwriteProvider>
            </SettingsContext.Provider>
        </BusProvider>
    )
}
