import cn from "classnames"
import React, { useEffect, useState } from "react"
import { useQRCode } from "next-qrcode"
import { useSettings } from "src/layout/page/settingsContext"
import { getTranslation } from "src/lib/translations"
import { DownLoadDetails } from "src/sanity/types"
import { Heading, isMobile } from "src/utils"
import ButtonGroup from "../button-group/ButtonGroup"
import s from "./SendSms.module.scss"
import { createAppLinkUrl, createDeferredDownloadLink } from "src/lib/oneclick/util"
import { getGaIds, trackGTMEvent } from "src/lib/tagmanager"
import { useOneclickTracking } from "src/lib/oneclick"
import countryCodes from "./countrycodes"
import { getDefaultCountryCode } from "./CountryCodeSelect"
import AppStore from "../app-store/AppStore"
import InputField from "../input-field/InputField"
import { getCallToActionTrackingHandler } from "../call-to-action/CallToAction"
import { useExperiments } from "src/layout/page/experimentsContext"
import { IntercomUserCustomAttributes } from "src/lib/intercom"
import Icon, { IconType } from "../icon/Icon"
import TrustPilot from "../trustpilot/Trustpilot"

export default function BusinessSignupNew({ downloadDetails, signupType = "download", showOnlyform = false, className }: {downloadDetails: DownLoadDetails, signupType?: "download" | "contact-me", showOnlyform?: boolean, className?: string}) {
    const [stage, setStage] = useState<"createLead" | "companyInformation" | "download" | "contactMeReceipt">("createLead")
    const { lang, segment } = useSettings()
    const t = getTranslation("downloadOverlay", lang)
    const { ids, source, campaign, parsedSource } = useOneclickTracking()
    const [qrLink, setQrLink] = useState<string>("")
    const [mobileLink, setMobileLink] = useState<string>("")
    const [phone, setPhone] = useState(["", ""])
    const [email, setEmail] = useState("")
    const [cvr, setCVR] = useState("")
    const [customAttributes, setCustomAttributesRaw] = useState({})
    const setCustomAttributes = (newAttributes) => {
        setCustomAttributesRaw({ ...customAttributes, ...newAttributes })
    }
    const [isNewOrEstablishedBusiness, setIsNewOrEstablishedBusiness] = useState<false | "new" | "established">(null)
    const [isSupportedSwedishOrganisationType, setIsSupportedSwedishOrganisationType] = useState(false)
    const [intercomLeadId, setIntercomLeadId] = useState<string>("")
    const [isLoading, setIsLoading] = useState(false)
    const [isStage1FormValid, setIsStage1FormValid] = useState(false)
    const [didSubmitStage2, setDidSubmitStage2] = useState(false)

    const isBankingServicesPage = downloadDetails?._id === "e2471831-2669-4057-991e-a7c79f8cf9cf"
    // web_business_onboarding experiment
    const { activeExperiments } = useExperiments()
    const experiment = activeExperiments["web_business_onboarding"]
    const forceWebOnboarding = typeof window !== "undefined" && window.location.pathname === "/dk/erhverv/ref-dinero" && "web"
    const webBusinessOnboardingVariant = (forceWebOnboarding || experiment?.activeVariant) as "web" | "app"

    const showLeadOverlayUsps = activeExperiments["lbb_lead_overlay_usps"]?.activeVariant === "usps"

    useEffect(() => {
        (async () => {
            const gaIds = await getGaIds()
            setQrLink(createDeferredDownloadLink({ ids: { ...ids, ...gaIds }, linkSource: "qr", segment, lang, source, campaign }))
        })()
    }, [stage])

    // useEffect(() => {
    //     if (lang === "se" && !customAttributes["CVR company type"] && getSwedishOrganisationType(cvr)) {
    //         setCustomAttributes({ "CVR company type": getSwedishOrganisationType(cvr) })
    //     }
    // }, [customAttributes, cvr, lang])

    useEffect(() => {
        if (lang === "se" && customAttributes["CVR company type"]) {
            const supportedTypes = ["Enskild firma", "Aktiebolag"]
            setIsSupportedSwedishOrganisationType(supportedTypes.includes(customAttributes["CVR company type"]))
        }
    }, [customAttributes, lang])

    useEffect(() => {
        if (stage === "download" && isMobile) {
            (async () => {
                const gaIds = await getGaIds()
                const mobileLink = createAppLinkUrl({
                    ids: { ...ids, ...gaIds },
                    source,
                    campaign,
                    ...(downloadDetails?.onelink ? { onelinkUrl: downloadDetails?.onelink } : {}),
                })
                setMobileLink(mobileLink)

                // if (isMobile)
                // location.href = mobileLink
            })()
        }
    }, [stage])

    // Form validation
    useEffect(() => {
        const isPhoneValid = phone[1].length > 3
        const isEmailValid = email.match(/^[^@]+@[^@]+\.[^@]{2,}$/) !== null
        const isDKCvrValid = (cvr === "0" || cvr.length >= 7)
        console.log({ lang, signupType, isPhoneValid, isDKCvrValid, isEmailValid, isNewOrEstablishedBusiness, isBankingServicesPage })
        if (lang === "se") {
            if (signupType === "download")
                setIsStage1FormValid(isPhoneValid && isEmailValid && !!isNewOrEstablishedBusiness)
            if (signupType === "contact-me")
                setIsStage1FormValid(isPhoneValid)
        }
        else if ((lang === "dk" || lang === "en") && !isBankingServicesPage) {
            if (signupType === "download") {
                setIsStage1FormValid(isPhoneValid && isEmailValid && isDKCvrValid)
            } else {
                setIsStage1FormValid(isPhoneValid && isDKCvrValid)
            }
        } else
            setIsStage1FormValid(isPhoneValid || isEmailValid)
    }, [phone, email, cvr, isNewOrEstablishedBusiness, lang, signupType, isBankingServicesPage])

    useEffect(() => {
        // Pick up lead details from query params, to prefill the form
        const urlParams = new URLSearchParams(window.location.search)
        const phoneParam = urlParams.get("phone")
        const emailParam = urlParams.get("email")
        const cvrParam = urlParams.get("cvr")
        const couponParam = urlParams.get("coupon")
        const intercomLeadId = urlParams.get("intercomLeadId")

        if (phoneParam) {
            const countryCode = phoneParam.includes("+") ? phoneParam.slice(0, 3) : null
            setPhone([countryCode, phoneParam.slice(3)])
        }
        if (emailParam) {
            setEmail(emailParam)
        }
        if (cvrParam) {
            setCVR(cvrParam)
        }
        if (intercomLeadId && intercomLeadId !== "undefined") {
            setIntercomLeadId(intercomLeadId)
        }
        if (couponParam) {
            document.cookie = `BUSINESS_ONBOARDING_COUPON=${couponParam}; domain=.lunar.app; path=/; SameSite=Strict; Secure`
        }

    }, [])

    const Canvas: any = useQRCode().Canvas

    async function getRecaptchaToken() {
        return new Promise((resolve) => {
            window.grecaptcha.ready(async function() {
                window.grecaptcha.execute("6LfdMsUhAAAAAGicH2UnCNbICPSMUtPBDyb6M0pI", { action: "submit" }).then(async function(recaptchaClientToken) {
                    resolve(recaptchaClientToken)
                })
            })
        })
    }

    const title = signupType === "download" ? downloadDetails.title : downloadDetails.contactTitle
    const subtitle = signupType === "download" ? downloadDetails.subtitle : downloadDetails.contactSubtitle
    const ctaText = signupType === "download" ? downloadDetails.ctaText : downloadDetails.contactCtaText
    const timeInCopenhagen = new Date().toLocaleString("en-US", { timeZone: "Europe/Copenhagen", hour: "numeric", hour12: false })
    const hourInCopenhagen = parseInt(timeInCopenhagen.split(":")[0])
    const isWeekDay = new Date().getDay() >= 1 && new Date().getDay() <= 5
    const isSalesTeamAvailable = isWeekDay && hourInCopenhagen >= 9 && hourInCopenhagen <= 15 && hourInCopenhagen !== 12
    // const isSalesTeamAvailable = false // Temporarily disabled

    return (
        <div className={className}>
            <div className="stage">
                {stage === "createLead" && (
                    <>
                        {title && (<Heading className="heading-100 mb-small">{title}</Heading>)}
                        {subtitle && <div className="paragraph-100 mb-medium">{subtitle}</div>}
                        {signupType === "contact-me" && downloadDetails.showInboundPhoneNumber && isSalesTeamAvailable && (
                            <>
                                <div className="mb-large" style={{ verticalAlign: "middle" }}>
                                    <a href="tel:+4570716879"><button className={cn("button button--pill")} onMouseDown={getCallToActionTrackingHandler({ linkType: "external", title: "📞 70 71 68 79", type: "link" })}>📞 70 71 68 79</button></a>
                                    <small className="live-pulse" style={{ fontSize: "0.8em", verticalAlign: "middle" }}>{lang === "dk" ? "Vi sidder klar nu" : "We're online now"}</small>
                                </div>
                                <hr style={{ borderBottom: "1px solid var(--gray-60)", width: "100%" }} className={"mb-large"} />
                                <div className="paragraph-100 mb-medium">{lang === "dk" ? "Du kan også udfylde formularen herunder, så kontakter vi dig snarest." : "You can also fill out the form below, and we'll contact you within the next few days."}</div>
                            </>
                        )}
                        <form
                            action=""
                            noValidate
                            onSubmit={async (e) => {
                                e.preventDefault()

                                // Require one of the two
                                if (!isStage1FormValid) {
                                    return
                                }
                                setIsLoading(true)

                                if ((lang === "dk" || lang === "en") && webBusinessOnboardingVariant !== "web")
                                    setStage(signupType === "download" && !isBankingServicesPage ? "download" : "contactMeReceipt")
                                if (lang === "se")
                                    setStage(signupType === "download" ? "companyInformation" : "contactMeReceipt")
                                if (lang === "no")
                                    setStage("download")

                                trackGTMEvent("signup_create_lead", {
                                    install_lead_type: signupType === "contact-me" ? "contact-me" : "signup-form",
                                    gclid: ids.gclid,
                                    cvr,
                                    ...(webBusinessOnboardingVariant ? { onboarding_platform: webBusinessOnboardingVariant } : {}),
                                })
                                if (cvr.length >= 7 && signupType === "download")
                                    trackGTMEvent("signup_cvr", {
                                        cvr,
                                    })

                                if (phone[1].length > 3)
                                    trackGTMEvent("send-link-by-sms", {
                                        sms_phone_number_country_code: phone[0].replaceAll("+", ""),
                                        sms_phone_number_country: countryCodes.find(cc => cc.phoneCode === phone[0])?.name.en || phone[0].replaceAll("+", ""),
                                        sms_phone_number: phone[1],
                                        sms_did_change_country_code: getDefaultCountryCode(lang).value !== phone[0],
                                        gclid: ids.gclid,
                                        install_lead_type: "skip_install_lead", // This skips the install_lead event, as we trigger that through the "signup_create_lead" event
                                    })

                                // Store email, phone and CVR in cookies at top level domain
                                if (signupType === "download" && webBusinessOnboardingVariant === "web") {
                                    document.cookie = `BUSINESS_ONBOARDING_EMAIL=${email}; domain=.lunar.app; path=/; SameSite=Strict; Secure`
                                    document.cookie = `BUSINESS_ONBOARDING_PHONE=${phone.join("")}; domain=.lunar.app; path=/; SameSite=Strict; Secure`
                                    document.cookie = `BUSINESS_ONBOARDING_ORGANISATION_NUMBER=${cvr}; domain=.lunar.app; path=/; SameSite=Strict; Secure`
                                }

                                const gaIds = await getGaIds()
                                const recaptchaClientToken = await getRecaptchaToken()

                                // Decide on lead source
                                const referrer = location.href.split("?")[0].split("#")[0]
                                let leadSource = signupType === "download" ? "web-business-signup" : "web-business-contact-me"
                                if (referrer.includes("flyt-erhvervskonto") || referrer.includes("slip-for-skjulte-gebyrer") || campaign?.toLowerCase()?.includes("switching"))
                                    leadSource = "web-business-switching"
                                if (isBankingServicesPage)
                                    leadSource = "web-banking-services"
                                if (location.pathname.includes("ref-dinero"))
                                    leadSource = signupType === "download" ? "dinero-get-started" : "dinero-contact-me"

                                // Decide on actions
                                let actions = ["createLead", "sendSms", "sendEmail"]
                                if (lang === "se") {
                                    if (signupType === "contact-me")
                                        actions = ["createLead", "sendToPipedrive"]
                                    else
                                        actions = ["createLead", "sendSms", "sendEmail"]
                                }
                                if (["dk", "en"].includes(lang) && signupType === "contact-me")
                                    actions = ["createLead"]
                                if (webBusinessOnboardingVariant === "web" && signupType === "download")
                                    actions = ["createLead"]
                                if (isBankingServicesPage)
                                    actions = ["createLead", "sendToPipedrive"]

                                const body = {
                                    actions,
                                    email,
                                    phone: phone.join(""),
                                    cvr,
                                    lang,
                                    segment: "business",
                                    referrer,
                                    ids: { ...ids, ...gaIds },
                                    ...(intercomLeadId ? { intercomLeadId } : {}),
                                    ...(downloadDetails?.onelink ? { onelinkPath: new URL(downloadDetails.onelink).pathname.slice(1) } : {}),
                                    customAttributes: {
                                        ...customAttributes,
                                        ...(webBusinessOnboardingVariant ? { "Website lead experiments": `web_business_onboarding=${webBusinessOnboardingVariant}` } : {}),
                                    } as IntercomUserCustomAttributes,
                                    utm_source: parsedSource,
                                    utm_campaign: campaign,
                                    marketingConsent: true,
                                    leadSource,
                                    recaptchaClientToken,
                                    deviceType: isMobile ? "mobile" : "desktop",
                                }

                                const createLeadRes = await fetch(`/api/createLead`, {
                                    body: JSON.stringify(body),
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                })
                                const resBody = await createLeadRes.json()
                                if (resBody.intercomLeadId) {
                                    setIntercomLeadId(resBody.intercomLeadId)
                                }

                                if (webBusinessOnboardingVariant === "web") {
                                    location.href = "https://signup.lunar.app/business"
                                    setTimeout(() => {
                                        setIsLoading(false)
                                    }, 1000)
                                }
                            }}
                        >
                            {/* If is banking services page */}
                            {isBankingServicesPage && (
                                <>
                                    <InputField
                                        type="text"
                                        name="companyName"
                                        label={"Company name"}
                                        placeholder={"Enter your company name"}
                                        onChange={(value) => setCustomAttributes({ company_name: value })}
                                        className="mb-large"
                                    />
                                    <InputField
                                        type="email"
                                        name="email"
                                        label={t.emailLabel}
                                        placeholder={"name@company.com"}
                                        onChange={(value) => setEmail(value)}
                                        className="mb-large"
                                    />
                                    <InputField
                                        type="tel"
                                        name="phone"
                                        label={t.phoneNumberLabel}
                                        placeholder={"01234 567890"}
                                        setPhone={setPhone}
                                        className="mb-large"
                                        phoneValue={phone}
                                    />
                                </>
                            )}
                            {!isBankingServicesPage && (
                                <>
                                    <InputField
                                        type="tel"
                                        name="phone"
                                        phoneValue={phone}
                                        label={t.phoneNumberLabel}
                                        placeholder={t.phoneNumberPlaceholder}
                                        setPhone={setPhone}
                                        className="mb-large"
                                    />

                                    {/* Show email if Download form, and not contact */}
                                    {signupType === "download" && (
                                        <InputField
                                            type="email"
                                            name="email"
                                            label={t.emailLabel}
                                            placeholder={t.emailPlaceholderBusiness}
                                            value={email}
                                            onChange={(value) => setEmail(value)}
                                            className="mb-large"
                                        />
                                    )}

                                    {/* In Denmark we show CVR field, in Sweden we ask if they have an established biz */}
                                    {["dk", "en"].includes(lang) && ["download", "contact-me"].includes(signupType) && (
                                        <>
                                            <InputField
                                                type={lang === "se" ? "text" : "number"}
                                                name="cvr"
                                                value={cvr === "0" ? "" : cvr}
                                                label={t.organisationNumberLabel}
                                                placeholder={t.organisationNumberPlaceholder}
                                                onChange={(value) => setCVR(value)}
                                                className="mb-small"
                                            />
                                            <div style={{ display: "flex", justifyContent: "flex-start" }} className={cn("mb-large")}>
                                                <input
                                                    type="checkbox"
                                                    name="doesNotHaveCVRNumber"
                                                    id="doesNotHaveCVRNumber"
                                                    onChange={(e) => { e.target.checked ? setCVR("0") : setCVR("") }}
                                                />
                                                <label className={cn(s["send-sms__input__label"])} style={{ fontSize: "14px" }} htmlFor="doesNotHaveCVRNumber">{t.iDontHaveACVRNumberYet}</label>
                                            </div>
                                        </>
                                    )}

                                    {lang === "se" && signupType === "download" && (
                                        <>
                                            <div style={{ display: "flex", justifyContent: "flex-start" }} className={cn("mb-large")}>
                                                <input
                                                    className={cn(s["send-sms__input__radio"])}
                                                    type="radio"
                                                    name="newOrEstablished"
                                                    id="newBusiness"
                                                    onChange={(e) => { if(e.target.checked) { setIsNewOrEstablishedBusiness("new"); setCVR("0") } }}
                                                />
                                                <label className={cn(s["send-sms__input__label"])} style={{ fontSize: "14px" }} htmlFor="newBusiness">Jag startar ett nytt företag</label>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "flex-start" }} className={cn("mb-large")}>
                                                <input
                                                    className={cn(s["send-sms__input__radio"])}
                                                    type="radio"
                                                    name="newOrEstablished"
                                                    id="establishedBusiness"
                                                    onChange={(e) => { if(e.target.checked) { setIsNewOrEstablishedBusiness("established") } }}
                                                />
                                                <label className={cn(s["send-sms__input__label"])} style={{ fontSize: "14px" }} htmlFor="establishedBusiness">Jag har ett etablerat företag</label>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}

                            <ButtonGroup className="mb-large">
                                <button type="submit" disabled={!isStage1FormValid || isLoading} className={cn("button button--pill")}>
                                    {/* Show spinner when loading */}
                                    {isLoading ? <Icon type={IconType.LOADING_SPINNER} /> : ctaText}
                                </button>
                            </ButtonGroup>
                            <div className={cn("paragraph-50")} style={{ fontSize: "12px", color: "var(--gray-100)" }}>{t.businessSignupDisclaimer}</div>
                        </form>
                    </>
                )}
                {stage === "companyInformation" && (
                    <>
                        <Heading className="heading-100 mb-medium">{isNewOrEstablishedBusiness === "new" ? "Välj bolagsform för den verksamhet du vill etablera" : "Vilken bolagsform har ditt företag?"}</Heading>
                        {/* {t.businessSignupStage2Description && <div className="paragraph-200 mb-large">{t.businessSignupStage2Description}</div>} */}
                        <form onSubmit={async (e) => {
                            e.preventDefault()

                            if (isNewOrEstablishedBusiness === "established" && cvr === "")
                                return


                            setStage("download")

                            trackGTMEvent("signup_cvr", {
                                cvr,
                            })

                            const recaptchaClientToken = await getRecaptchaToken()
                            const body = {
                                actions: ["createLead"],
                                intercomLeadId,
                                ...(cvr ? { cvr } : {}),
                                ...(Object.keys(customAttributes).length > 0 ? { customAttributes } : {}),
                                lang,
                                recaptchaClientToken,
                            }
                            setDidSubmitStage2(true)

                            await fetch(`/api/createLead`, {
                                body: JSON.stringify(body),
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },

                            })

                        }}>

                            {/* Company forms */}
                            {["Aktiebolag", "Enskild firma", "Annat", isNewOrEstablishedBusiness === "new" ? "Vet inte än" : null].filter(Boolean).map((companyType) => (
                                <div style={{ display: "flex", justifyContent: "flex-start" }} key={companyType} className={cn("mb-large")}>
                                    <input
                                        className={cn(s["send-sms__input__radio"])}
                                        type="radio"
                                        name="companyForm"
                                        id={companyType}
                                        onChange={(e) => { e.target.checked && setCustomAttributes({ "CVR company type": companyType }) }}
                                        required
                                    />
                                    <label className={cn(s["send-sms__input__label"])} style={{ fontSize: "14px" }} htmlFor={companyType}>{companyType}</label>
                                </div>
                            ))}

                            {isNewOrEstablishedBusiness == "established" && (
                                <InputField
                                    type={lang === "se" ? "text" : "number"}
                                    name="cvr"
                                    value={cvr === "0" ? "" : cvr}
                                    label={t.organisationNumberLabel}
                                    placeholder={t.organisationNumberPlaceholder}
                                    onChange={(value) => setCVR(value)}
                                    className="mb-xlarge"
                                />
                            )}
                            <button type="submit" disabled={(isNewOrEstablishedBusiness === "established" && cvr === "") || !customAttributes?.["CVR company type"] || didSubmitStage2} className={cn(s["send-sms__input__button"], "button button--pill")}>
                                {t.businessSignupStage2CtaText}
                            </button>
                        </form>
                    </>
                )}

                {stage === "download" && (
                    <div style={{
                        display: "flex",
                        gap: "2em",
                    }}>
                        <div style={{
                            width: "66%",
                            minWidth: "66%",
                            flex: "1 1 66%",
                        }}>
                            {(lang !== "se" || (lang === "se" && isSupportedSwedishOrganisationType)) && (
                                <>
                                    <div className="label-200 mb-medium">{t.businessSignupStage3SubTitle}</div>
                                    <Heading className="heading-100 mb-xlarge">{t.businessSignupStage3Title}</Heading>
                                    {/* </div> */}
                                    <div className="label-200 mb-medium">{t.businessSignupStage3StepsTitle}</div>
                                    <ol style={{ paddingInlineStart: "20px", listStyleType: "decimal" }}>
                                        {t.businessSignupStage3Steps.split("\n").map((step, i) => (
                                            <li key={i} className="paragraph-200 mb-small" style={{ listStyle: "inherit" }}>{step}</li>
                                        ))}
                                    </ol>
                                    <div className="hide-on-desktop">
                                        <AppStore onelinkUrl={mobileLink} orientation="horizontal" />
                                    </div>
                                    {cvr === "" || cvr === "0" && ["dk", "en"].includes(lang) && (
                                        <small style={{ fontStyle: "italic", margin: "30px 0", display: "block" }}>{t.businessSignupStage3CVRHint}</small>
                                    )}
                                </>
                            )}
                            {(lang === "se" && !isSupportedSwedishOrganisationType) && (
                                <>
                                    <p className="heading-100 mb-small"><b style={{ fontWeight: "bolder" }}>Tack för ditt intresse.</b></p>
                                    <p className="mb-small">För närvarande kan vi dessvärre inte erbjuda din bolagsform ett företagskonto.</p>
                                    <p className="mb-small">Du kommer få ett mail så snart vi är redo att välkomna din bolagsform. </p>
                                    <p className="mb-small">Du kan redan nu ladda ner Lunar-appen, så att du är redo att ansöka så snart vi kan ta emot er.</p>
                                </>
                            )}
                        </div>
                    </div>
                )}
                {stage === "contactMeReceipt" && (
                    <>
                        <Heading className="paragraph-300 mb-large">{t.businessSignupContactMeReceiptTitle}</Heading>
                        <Heading className="heading-100 mb-xlarge">{t.businessSignupContactMeReceiptSubtitle}</Heading>
                        {/* <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src="https://cdn.sanity.io/images/inp43svr/production/f9d77a02c77c186e495e183aba7367fb1e0e1cd0-3800x3800.jpg?w=900&h=900&max-h=2000&q=80&auto=format" /> */}
                    </>
                )}
            </div>
            {!showOnlyform && (

                showLeadOverlayUsps && !isBankingServicesPage ? (
                    <div style={{ padding: "36px 24px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        borderLeft: "1px solid var(--gray-30)",
                        margin: "40px 0" }}>
                        {(lang === "dk" ?
                            [
                                [
                                    IconType.LIGHTNING,
                                    "Ansøg digitalt på få minutter",
                                    "Kom i gang med din erhvervskonto hurtigt og nemt.",
                                ],
                                [
                                    IconType.VOUCHER,
                                    "0 kr. i oprettelse",
                                    "Med Essential og Limitless slipper du for oprettelsesgebyrer.",
                                ],
                                [
                                    IconType.CHECK,
                                    "Alt, du skal bruge",
                                    "Lunar tilbyder alt, hvad du behøver til din erhvervskonto: NemKonto, firmakort, automatisk bogføring (Billy, Dinero eller e-conomic), erhvervslån og forsikringer.",
                                ],
                            ]
                            :
                            [
                                [
                                    IconType.LIGHTNING,
                                    "Apply digitally in minutes",
                                    "Get started with your business account quickly and easily.",
                                ],
                                [
                                    IconType.VOUCHER,
                                    "0 kr. setup fee",
                                    "With Essential and Limitless, you avoid setup fees.",
                                ],
                                [
                                    IconType.CHECK,
                                    "Everything you need",
                                    "Lunar offers everything you need for your business account: NemKonto, credit card, automatic bookkeeping (Billy, Dinero or e-conomic), business loans, and insurance.",
                                ],
                            ]

                        ).map(([icon, title, subtitle]) => (
                            <div key={title} style={{ display: "flex", gap: "12px" }}>
                                <div><Icon type={(icon as IconType)} fill={true} style={{ marginTop: "0.25em", height: "1em", width: "1em" }} /></div>
                                <div key={title} className="mb-large">
                                    <h3 className="label-100">{title}</h3>
                                    <p className="paragraph-100 text-gray-100">{subtitle}</p>
                                </div>
                            </div>
                        ))}
                        <div style={{ marginTop: "20px", width: "90%", margin: "20px auto" }}>
                            <TrustPilot />
                        </div>
                    </div>
                )
                    : (
                        <div className="image-container hide-on-mobile">
                            <img src={signupType === "contact-me" ? "https://cdn.sanity.io/images/inp43svr/production/669362b2f8cfb6572d5de15bd4d2553a2712f63f-578x558.png" : "https://cdn.sanity.io/images/inp43svr/production/8479f28d853774c48e0f4dbdf953e4b2bedc86c1-1722x1162.jpg?rect=500,50,1080,1080&w=900&h=900&max-h=2000&q=80&auto=format"} />
                            {stage === "download" && !(lang === "se" && !isSupportedSwedishOrganisationType) && (
                                <div className="hide-on-mobile" style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "2em",
                                    position: "absolute",
                                    left: "0",
                                    right: "0",
                                    top: "0",
                                    bottom: "0",
                                    background: "rgba(0,0,0,0.5)",
                                    color: "white",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}>
                                    <div className="label-200 mb-small">{t.businessSignupDownloadAppTitle}</div>
                                    {qrLink && (
                                        <Canvas
                                            text={qrLink}
                                            options={{
                                                width: 250,
                                                errorCorrectionLevel: "L",
                                                margin: 2,
                                            }}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    )
            )}
        </div>
    )
}
