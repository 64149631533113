import React, { useEffect, useRef } from "react"
import BlockContent from "@sanity/block-content-to-react"
import cn from "classnames"
import s from "./RichText.module.scss"
import { SanityRichText } from "../../sanity/types"
import { config } from "../../sanity/config"
import { LinkHandler } from "../link-handler/LinkHandler"
import { slugify } from "src/templates/blog/blogHelper"

interface RichTextProps {
    blocks: SanityRichText
    className?: string
    tag?: keyof JSX.IntrinsicElements
}

function getFirstTextNode(props) {
    const firstChild = props.children?.[0]
    if (typeof firstChild === "string")
        return firstChild

    if (firstChild?.props?.node)
        return getFirstTextNode(firstChild.props.node)

    return props
}

const serializers = {
    types: {
        block: (props) => {
            if (/^h\d/.test(props.node.style)) {
                const text = getFirstTextNode(props)
                const headingMap = {
                    "h1": "heading-300",
                    "h2": "heading-200",
                    "h3": "heading-100",
                    "h4": "heading-50",
                }
                return React.createElement(props.node.style, { id: slugify(text), className: "light-font-weight " + headingMap[props.node.style] }, props.children)
            }

            return BlockContent.defaultSerializers.types.block(props)
        },
    },

    marks: {
        link: ({ mark, children }) => {
            if(!mark.linkType) {
                return children
            }

            return <LinkHandler link={mark}>{children}</LinkHandler>
        },
        // bold: ({ children }) => <span className="bold">{children}</span>,
        colored: ({ children }) => <span className="colored">{children}</span>,
        light: ({ children }) => <span className="light-font-weight">{children}</span>,
    },
}

export default function RichText({ blocks: initBlocks, className, tag }: RichTextProps) {
    const blocks = useRef(initBlocks)

    useEffect(() => {
        if (typeof window !== "undefined" && initBlocks && window.location.search) {
            // Replace moustache tags in blocks with query parameters
            const parameters = Object.fromEntries(new URLSearchParams(window.location.search))
            // parameters to object
            blocks.current = initBlocks.map(block => {
                if (block._type === "block" && block.children) {
                    block.children = block.children.map(child => {
                        if (child.text?.includes("{{")) {
                            parameters && Object.keys(parameters).forEach(key => {
                                child.text = child.text.replace("{{" + key + "}}", parameters[key])
                            })
                        }
                        return child
                    })
                }
                return block
            })
        }
    }, [])

    if (!blocks.current || blocks.current.length === 0) {
        return null
    }

    const Tag = tag || "div"

    return (
        <Tag className={cn(s["rich-text"], className)}>
            <BlockContent blocks={blocks.current} serializers={serializers} projectId={config.projectId} dataset={config.dataset} />
        </Tag>
    )
}
