import React, { useEffect, useRef } from "react"
import cn from "classnames"
import s from "./Menu.module.scss"
import { LinkItem } from "src/sanity/types"
import { buildMenus, cleanBuiltMenus, LinkChild, ModifiedLinkGroup } from "src/utils"
import { LinkHandler } from "../../components/link-handler/LinkHandler"

interface MenuProps {
    label: string
    children: LinkChild[]
    id: string
    isActive?: boolean
    big?: boolean
}

// is element contained in target
export function containsElement(target: HTMLElement, element: HTMLElement) {
    let e = element.parentNode

    while (e) {
        if (e === target) {
            return true
        }

        e = e.parentNode
    }

    return false
}

export default function Menu({
    label,
    children = [],
    id,
    isActive,
    big = false,
}: MenuProps) {
    const ref = useRef<HTMLDivElement>()
    const refOuter = useRef<HTMLDivElement>()
    const menu = buildMenus([...(children || [])])
    const cleanMenu = cleanBuiltMenus(menu)

    const hasThreeLevelHierarchy = cleanMenu[0].type === "linkGroup" && !!((cleanMenu[0] as ModifiedLinkGroup)?.children?.[0] as ModifiedLinkGroup)?.children?.[0]

    useEffect(() => {
        if (ref.current) {
            const { left } = ref.current.getBoundingClientRect()
            const leftBuffer = 100

            if (left < leftBuffer) {
                // ref.current.style.transform = `translateX(calc(-100% + 40em ))`
            }
        }
    }, [big])

    return (
        <>
            <div
                className={cn(s["menu"], isActive ? s["menu--is-active"] : null)}
                ref={refOuter}
            >
                <button
                    className={cn(s["menu__button"], "label-100")}
                    aria-controls={"menu-" + id}
                >
                    {label}
                </button>

            </div>
            <div
                className={cn(s["menu__popup"], {
                    [s["menu__popup--big"]]: big,
                    [s["menu__popup--three-level-hierarchy"]]: hasThreeLevelHierarchy,
                })}
                ref={ref}
                id={"menu-" + id}
            >

                <ul className={cn(s["menu__popup__list"])}>
                    {cleanMenu.map((menuItem: LinkChild, index: number) => {
                        const delay = ((index + 0) * 0.05) + "s"

                        if (menuItem.type === "linkCollector") {
                            return (
                                <ul key={menuItem.type + index} className={s["menu__popup__collector"]}>
                                    {menuItem.children.map((child: LinkItem, childIndex: number) => {
                                        return (
                                            <LinkDisplay
                                                key={child.type + childIndex}
                                                menuItem={child}
                                                delay={delay}
                                                big={big}
                                            />
                                        )
                                    })}
                                </ul>
                            )
                        } else {
                            return (
                                <LinkDisplay
                                    key={menuItem.type + index}
                                    menuItem={menuItem}
                                    delay={delay}
                                    big={big}
                                />
                            )
                        }
                    })}
                </ul>
            </div>
        </>
    )
}

interface LinkDisplayProps {
    menuItem: LinkChild
    delay: string
    big: boolean
    arrow?: boolean
}

export const LinkDisplay = ({ menuItem, delay, big }: LinkDisplayProps) => {
    if (menuItem.type === "linkGroup") {
        return (
            <li
                key={menuItem.id}
                className={cn(s["menu__popup__item"])}
                style={{
                    animationDelay: delay,
                }}
            >
                <strong
                    className={cn(s["menu__popup__heading"], "label-100")}
                >
                    {menuItem.label}
                </strong>

                <ul className={s["menu__popup__sublist"]}>
                    {menuItem.children.map((menuItemChild: LinkChild, childIndex) => {
                        if (menuItemChild.type === "linkCollector") {
                            return (
                                <ul key={menuItemChild.type + childIndex} className={s["menu__popup__collector"]}>
                                    {menuItemChild.children.map((collectorChild: LinkItem, collectorIndex: number) => {
                                        return (
                                            <LinkDisplay
                                                key={collectorChild.type + collectorIndex}
                                                menuItem={collectorChild}
                                                delay={delay}
                                                big={big}
                                            />
                                        )
                                    })}
                                </ul>
                            )
                        }
                        if (menuItemChild.type === "linkGroup") {
                            return (
                                <LinkDisplay key={menuItemChild.id} big={big} delay={delay} menuItem={menuItemChild} />
                            )

                        }

                        return (
                            <li key={menuItemChild.id} className={cn(s["menu__popup__sublist__item"], s["menu__link"], menuItemChild.markAsNew ? s["menu__popup__sublist__item--new"] : null)}>
                                <LinkHandler link={menuItemChild} className="label-100" />
                            </li>
                        )
                    })}
                </ul>
            </li>
        )
    }

    // very special link handling for elements in big popup
    if (menuItem.type === "link") {
        return (
            <li
                style={{
                    animationDelay: delay,
                }}
                key={menuItem.id}
                className={cn(
                    s["menu__popup__item--solo"],
                    s["menu__popup__item"],
                    s["menu__link"],
                )}
            >
                <LinkHandler className={cn({ "label-100": !menuItem.withArrow })} link={menuItem} />
            </li>
        )
    }
}
