import React, { ReactNode, useEffect, useRef, useState } from "react"
import { Link } from "src/components/link/Link"
import cn from "classnames"
import { Container } from "../container/Container"

import s from "./Header.module.scss"
import Icon, { IconType } from "src/components/icon/Icon"
import { GlobalSettings, HeaderStyle, InternalLink, LinkItem, PageThemeType } from "src/sanity/types"
import SegmentSelector from "./SegmentSelector"
import { debounce, Only } from "src/utils"
import DownloadOverlay from "src/components/download-overlay/DownloadOverlay"
import Menu from "./Menu"
import MobileNavigation from "./MobileNavigation"
import { useSettings } from "../page/settingsContext"
import { LinkHandler } from "../../components/link-handler/LinkHandler"
import buildLink from "../../sanity/buildLink"
import LoginOverlay from "src/components/login-overlay/LoginOverlay"
import { useRouter } from "next/router"
import { getCallToActionTrackingHandler } from "src/components/call-to-action/CallToAction"

interface HeaderProps {
    children?: ReactNode
    globalSettings: GlobalSettings
    style: HeaderStyle
    pageThemeType?: PageThemeType
    pageThemeColor?: string
    documentId?: string
}

export function Header({ globalSettings, style, pageThemeColor, documentId }: HeaderProps): JSX.Element {
    const headerRef = useRef<HTMLElement>()
    const router = useRouter()
    const [menuOpen, setMenuOpen] = useState(false)
    const [isHoveringMenu, setIsHoveringMenu] = useState(false)
    const { lang, segment, translations: t } = useSettings()
    const isBankingServicesPage = ["09b34c1f-01d1-4b3d-a57f-4b09d124c92d", "7669775c-d703-41c3-b553-74b3012cee82", "0a0a64d8-cd87-4e53-b66d-c99f65c5c4f4"].includes(documentId?.replace("drafts.", ""))
    const homeLink: InternalLink = isBankingServicesPage ? { id: "", title: "", "linkType": "internal", type: "link", url: { lang: "en", segment: "business", slug: "banking-services", type: "link" } } : {
        id: "",
        title: "",
        linkType: "internal",
        type: "link",
        url: {
            type: "frontpage",
            lang,
            segment,
            slug: "",
        },
    }
    const bankingServicesMenu = [
        {
            id: "link1",
            title: "Banking services",
            type: "link",
            linkType: "internal",
            url: {
                segment: null,
                lang: null,
                slug: "banking-services",
            },
        },
        {
            id: "link2",
            title: "Product",
            type: "link",
            linkType: "internal",
            url: {
                segment: null,
                lang: null,
                slug: "banking-services-product",
            },
        },
        {
            id: "link3",
            title: "Use cases",
            type: "link",
            linkType: "internal",
            url: {
                segment: null,
                lang: null,
                slug: "banking-services-use-cases",
            },
        },
        {
            id: "link4",
            title: "Developers",
            type: "link",
            linkType: "external",
            url: "https://docs.banking-services.lunar.app",
        },
    ]

    useEffect(() => {
        const onScroll = () => {
            const scrollPosition = window.scrollY || window.pageYOffset || document.documentElement.scrollTop
            const viewportHeight = window.innerHeight

            if (scrollPosition > viewportHeight && !document.body.classList.contains("scrolled-past-top-module")) {
                // Page has scrolled past 1 viewport height
                document.body.classList.add("scrolled-past-top-module")
            } else if (scrollPosition < viewportHeight && document.body.classList.contains("scrolled-past-top-module")) {
                document.body.classList.remove("scrolled-past-top-module")
            }
        }
        const debouncedOnScroll = debounce(onScroll, 25)

        window.addEventListener("scroll", debouncedOnScroll)

        return () => {
            window.removeEventListener("scroll", debouncedOnScroll)
        }
    }, [])

    useEffect(() => {
        if (menuOpen) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "auto"
        }
    }, [menuOpen])

    // close menu and popup when going to a different page
    useEffect(() => {
        const routeChangeComplete = () => { setMenuOpen(false); setIsHoveringMenu(false) }

        router.events.on("routeChangeComplete", routeChangeComplete)

        return () => {
            router.events.off("routeChangeComplete", routeChangeComplete)
        }
    }, [router])

    let loginLink: LinkItem
    if (lang === "dk" && segment === "business")
        loginLink = { linkType: "external", title: "Log ind", url: "https://business.lunar.app/auth/signin?country=dk",  id: "login-link", type: "link", gtmEvent: "login-click" }
    if (lang === "en" && segment === "business" && !isBankingServicesPage)
        loginLink = { linkType: "external", title: "Log in", url: "https://business.lunar.app/auth/signin?country=dk",  id: "login-link", type: "link", gtmEvent: "login-click" }
    if (lang === "se" && segment === "private")
        loginLink = { linkType: "event", title: "Logga in", event: "loginOverlay", id: "login-link", type: "link", gtmEvent: "login-click" }
    if (lang === "se" && segment === "business")
        loginLink = { linkType: "external", title: "Logga in", url: "https://business.lunar.app/auth/signin?country=se",  id: "login-link", type: "link", gtmEvent: "login-click" }
    if (lang === "no" && segment === "business")
        loginLink = { linkType: "external", title: "Logg inn", url: "https://business.lunar.app/auth/signin?country=no",  id: "login-link", type: "link", gtmEvent: "login-click" }

    const hamburgerCTATracker = getCallToActionTrackingHandler({ event: "hamburger-menu", linkType: "event", title: "Hamburger menu" })
    return (
        <>
            <header
                ref={headerRef}
                className={cn(
                    s["header"],
                    s["header--" + style],
                )}
                style={pageThemeColor !== "transparent" ? { "--nav-background" : pageThemeColor } as React.CSSProperties : undefined}
            >
                <Container>
                    <nav className={s["header__inner"]} >
                        <Link href={buildLink(homeLink)} className={s["header__logo"]}>
                            <Icon type={IconType.LOGO} />
                            <span className="visually-hidden">
                                Lunar {t?.header?.home.toLocaleLowerCase()}
                            </span>
                        </Link>

                        <div className={s["header__nav"]}>
                            {!isBankingServicesPage && (
                                <div className={s["header__segment"]}>
                                    <SegmentSelector dark={segment === "business"} />
                                </div>
                            )}

                            <ul className={cn(s["header__menu"], isHoveringMenu ? s["header__menu--is-active"] : null)} onMouseOver={() => setIsHoveringMenu(true)} onMouseLeave={() => setIsHoveringMenu(false)}>
                                {(isBankingServicesPage ? bankingServicesMenu : globalSettings.navigation?.header || []).map((i, index) => {

                                    if (i.type !== "linkGroup") {
                                        if (i.linkType === "event") {
                                            return (
                                                <li className={cn(s["header__menu__item"], s["header__menu__item--last"])} key={i.id}>
                                                    <LinkHandler link={i} className={cn(s["header__menu-link"], "label-100")} prefetch={false} />
                                                </li>
                                            )
                                        }
                                        return (
                                            <li className={cn(s["header__menu__item"])} key={i.id}>
                                                <LinkHandler link={i} className={cn(s["header__menu-link"], "label-100")} prefetch={false} />
                                            </li>
                                        )
                                    }


                                    return (
                                        <li className={s["header__menu__item"]} key={i.id} >
                                            <Menu
                                                big={index === 0}
                                                id={i.id}
                                                label={i.label}
                                                isActive={true}
                                            >
                                                {i.children}
                                            </Menu>
                                        </li>
                                    )
                                })}
                            </ul>

                        </div>
                        <LoginOverlay />
                        <div className={cn(s["header__right-side"])}>
                            <Only if={loginLink}>
                                <LinkHandler className={cn(s["header__login"], "label-100")} link={loginLink} />
                            </Only>
                            <Only if={globalSettings.headerCTALabel}>
                                <div className={s["header__download"]}>
                                    <DownloadOverlay />
                                </div>
                            </Only>

                            <div className={s["header__open-menu"]}>
                                <button onMouseDown={hamburgerCTATracker} onClick={() => setMenuOpen(i => !i)}>
                                    <Icon type={IconType.HAMBURGER} />
                                </button>
                            </div>
                        </div>
                    </nav>
                </Container>
            </header>
            {menuOpen &&
                <MobileNavigation menuOpen={menuOpen} loginLink={loginLink} close={() => setMenuOpen(false)} />
            }
            <div className={cn(s["header__backdrop"], isHoveringMenu ? s["header__backdrop--active"] : null)} />
        </>
    )
}

